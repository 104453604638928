.footer {
  color: white;
  font-size: 12px;
  text-align: center;
  margin-top: 1rem;
  padding: 1rem;
  position: fixed;
  border-top: 2px solid;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
}

.footer a {
  font-weight: bold;
  color: #fff !important;
  text-decoration: none;
}

.link {
  font-weight: bold;
  color: #fff !important;
  text-decoration: none;
  display: flex !important;
  align-items: center !important;
}

.linkIcon {
  font-size: 1.1rem !important;
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}
