.sui-layout-body__inner{
    max-width: 100%;
    margin-left: 4px;
    margin-right: 4px;
}

.sui-layout-header{
    padding-top: 8px;
    padding-bottom: 8px;
}

.sui-result + .sui-result{
    margin-top: 12px;
}

.sui-result{
    margin-top: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
}